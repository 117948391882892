import { IAlert } from "models/IAlert";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { display, hide } from "./index";

interface IDisplayAlertArgs extends Omit<IAlert, "open"> {}

export const displayAlert = createAsyncThunk<void, IDisplayAlertArgs>(
  "alert/display",
  async ({ message, type, timeout, requiereAyuda }, { dispatch }) => {
    dispatch(display({ message, type, requiereAyuda }));
    setTimeout(() => {
      dispatch(hide());
    }, timeout);
  }
);
