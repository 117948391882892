import { IDataFichaje } from "models/IDataFichaje";
import { IDisplayAlertArgs } from "models/IAlert";
import { IQrSingIng } from "models/IQrSinging";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as useCases from "app/Signing";
import { isAxiosError } from "@architecture-it/core";

import { displayAlert } from "../alert/asyncActions";

export const getLastSigningAction = createAsyncThunk("getLastSigning", async () => {
  try {
    const lastSigning = await useCases.getLastSigning();

    return lastSigning;
  } catch (error: any) {
    return null;
  }
});

export const postSigningAction = createAsyncThunk(
  "postSigning",
  async (param: IDataFichaje, { dispatch }) => {
    const alert = {
      type: "error",
      message: "",
      requiereAyuda: false,
      timeout: 10000,
    } as IDisplayAlertArgs;

    try {
      const data = await useCases.postSigning(param);

      alert.type = "success";
      alert.message = data[0].message;
      dispatch(displayAlert(alert));
      dispatch(getLastSigningAction());

      return data[0];
    } catch (error: any) {
      if (isAxiosError(error)) {
        const errorCode = error.response?.status as number;
        let response = error.response as any;

        alert.message = response.data[0].message;
        switch (errorCode) {
          case 400:
            alert.requiereAyuda = response.data[0].property !== "errorMail";
            dispatch(displayAlert(alert));
            break;
          case 401:
            alert.requiereAyuda = false;
            break;
          default:
            alert.message = "Ha ocurrido un error, por favor, intente nuevamente.";
            alert.requiereAyuda = false;
            break;
        }
        dispatch(displayAlert(alert));

        return response.data[0] as IQrSingIng;
      }
    }
  }
);
