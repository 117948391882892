import { IDataFichaje } from "models/IDataFichaje";
import SigningService from "services/apis/SigningService";

export async function getLastSigning() {
  const { data } = await SigningService.getLastSigning();

  return data;
}

export async function postSigning(param: IDataFichaje) {
  const { data } = await SigningService.postSigning(param);

  return data;
}
