import { configureStore } from "@reduxjs/toolkit";
import { ReactReduxContextValue } from "react-redux";
import React from "react";

import { reducers } from "./reducers";

export const store = configureStore({
  reducer: reducers,
});

export interface Action<T = any> {
  type: T;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export interface AnyAction extends Action {
  // Allows any extra properties to be defined in an action.

  [extraProps: string]: any;
}

export const TiemposAppContext = React.createContext<ReactReduxContextValue<unknown, AnyAction>>(
  null as any
);

export default store;
