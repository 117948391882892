import { IModal } from "models/IModal";

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const initialState: IModal = {
  src: "",
  open: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    display: (state, action) => {
      state.open = true;
      state.src = action.payload.src;
    },
    hideModal: (state) => {
      state.open = false;
      state.src = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { hideModal, display } = modalSlice.actions;

export default modalSlice.reducer;

export const selectModal = (state: RootState) => state.modalReducer;
