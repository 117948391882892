import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "store/store";
import theme from "utils/theme";

import * as serviceWorkerRegistration from "./services/serviceWorkerRegistration";
import App from "./App";
import rum from "./monitor";
import { msalInstance } from "./msalInstance";
rum.setInitialPageLoadName("Home");

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App msalInstance={msalInstance} />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();
